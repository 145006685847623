import PropTypes from 'prop-types'
import StarIcon from '../StarIcon'
import styles from './StarIconsList.module.scss'

/**
 * Name: StarIconsList
 * Desc: Render start icon list
 * @param {func} onInputRatingChange
 * @param {func} onStarClick
 * @param {number} value
 * @param {func} setValue
 * @param {number} index

 */
const StarIconsList = ({
  data,
  onInputRatingChange,
  onStarClick,
  value,
  setValue,
  index,
  id: productCode,
  readOnly,
}) => {
  const { name, editing, customClass } = data
  const finalIndex = index + 1
  const id = `${name}_${finalIndex}${productCode ? `_${productCode}` : ''}`

  const handleOnClick = (e) => {
    !readOnly && onStarClick(finalIndex, value, name, e, data, setValue)
  }

  const handleOnChange = (e) => {
    !readOnly && onInputRatingChange(e.target.value, data, finalIndex, name)
  }

  const labelClasses = `${editing ? styles.hoverStar : ''} ${
    value >= finalIndex ? styles.withFullStar : ''
  } ${styles.label} `
  const isChecked = value === finalIndex
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <label key={`label_${id}`} className={labelClasses} onClick={handleOnClick}>
      <input
        name={name}
        key={`input_${id}`}
        type="radio"
        value={finalIndex}
        checked={isChecked}
        onChange={handleOnChange}
        className={styles.radioStarHidden}
      />
      <StarIcon
        readOnly={readOnly}
        index={finalIndex}
        value={value}
        name={name}
        customClass={customClass}
      />
    </label>
  )
}

// Default Props
StarIconsList.defaultProps = {
  id: '',
}

// Props Validation
StarIconsList.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    editing: PropTypes.bool,
    customClass: PropTypes.string,
  }),
  onInputRatingChange: PropTypes.func,
  onStarClick: PropTypes.func,
  value: PropTypes.number,
  setValue: PropTypes.func,
  index: PropTypes.number,
  readOnly: PropTypes.bool,
  id: PropTypes.string,
}

export default StarIconsList
