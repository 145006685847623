import { ENGLISH } from '@redux/constants'
import compose from 'lodash/fp/compose'
import { getOldApiUrl } from 'services/utils/configUtils'
import { checkArgsEmpty, sendRequest } from './apiHelper'

const OLD_API_URL = getOldApiUrl()

/**
 * Name: addToCart
 * Desc: add item in cart for PDP
 * @param {*} language
 * @param {*} reqParams
 */
const buildAddToCart = async (args) => {
  const [language = ENGLISH, cartReqParams] = args
  const { reqParams } = cartReqParams
  const url = `${OLD_API_URL}/api/cart/addtocart?callingLocation=react`
  const options = {
    method: 'POST',
    body: JSON.stringify(reqParams),
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': `${language}-CA`,
    },
  }
  return sendRequest(url, options)
}

/**
 * Export methods
 */
export const addToCart = compose(
  checkArgsEmpty,
  withStartAndEndTime
)(buildAddToCart)

/**
 * Higher order function that adds timing to an async function
 * @param {*} fn
 * @returns {object} - Contains startTime and endTime, and the request result
 */
function withStartAndEndTime(fn) {
  return async (...args) => {
    const startTime = new Date().getTime()
    const result = await fn(...args)
    const endTime = new Date().getTime()
    return { ...result, timing: { startTime, endTime } }
  }
}
