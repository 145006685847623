import PropTypes from 'prop-types'
import { cloneElement, Children } from 'react'

import EwayRouter from 'shared/EwayRouter'
import EwayLink from '../EwayLink'

/**
 * Composed component based on the <EwayLink /> component
 * It transforms the link based on the old eway route
 * @param {Object} rest
 * @param {Object} children
 * @param {string} href
 */

function EwayRouterLink({ href, children, ...props }) {
  if (hasNoChildren(children)) {
    return null
  }

  const link = EwayRouter.getUrl(href) || href

  if (childrenIsAnchorHtmlTag(children)) {
    return addLinkToChildren(children, link, props)
  }
  return (
    <EwayLink href={link || null} {...props}>
      {children}
    </EwayLink>
  )
}
function addLinkToChildren(children, link, rest) {
  return cloneElement(children, { href: link, ...rest })
}

function childrenIsAnchorHtmlTag(children) {
  return children.type === 'a'
}

function hasNoChildren(children) {
  return Children.count(children) === 0
}

// Props Validation
EwayRouterLink.propTypes = {
  children: PropTypes.object,
  href: PropTypes.string,
}

export default EwayRouterLink
