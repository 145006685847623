import PropTypes from 'prop-types'
import classNames from 'classnames'
import Text from 'shared/Text'
import Flex from '../Flex'
import styles from './LabelText.module.scss'

/**
 * Name: LabelText
 * Desc: Render Label and Text combination
 * @param {string} label
 * @param {string|number|element} text
 * @param {bool} fontFamilyLabel
 * @param {bool} fontFamilyText
 * @param {string} "data-test"
 * @param {string} variant
 */
const LabelText = ({
  label,
  text = '',
  fontFamilyLabel = '',
  fontFamilyText = '',
  'data-test': dataTest,
  color = 'secondary',
  variant = 'mdText',
  className,
}) => {
  const labelTextClasses = classNames(styles.labelText, className)
  return (
    <Flex className={labelTextClasses} flexWrap="wrap">
      <div className={styles.labelTextSection}>
        <Text
          text={label}
          color={color}
          variant={variant}
          fontFamily={fontFamilyLabel}
        />
      </div>
      <div className={styles.labelTextSection}>
        <Text
          text={text}
          variant={variant}
          color={color}
          fontFamily={fontFamilyText}
          data-test={dataTest}
        />
      </div>
    </Flex>
  )
}

// Props Validation
LabelText.propTypes = {
  label: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]),
  fontFamilyLabel: PropTypes.string,
  fontFamilyText: PropTypes.string,
  'data-test': PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
}

export default LabelText
