export default {
  en: {
    addToCartLabel: 'Add to Cart',
    thisItemNotAvailableLabel: 'This item is not available in this quantity',
    salesMultiple: `"{productCode}" was not added. It is sold in multiples of {salesMultiple} only. Please try again.`,
    minimumOrderMsg: `{productCode} was not added. This item has a minimum order quantity of {quantity}. Please try again.`,
  },
  fr: {
    addToCartLabel: 'Ajouter au panier',
    thisItemNotAvailableLabel: 'This item is not available in this quantity',
    salesMultiple: `"{productCode}" n'a pas été ajouté. Ce produit est vendu en multiples de {salesMultiple} seulement. Veuillez essayer à nouveau.`,
    minimumOrderMsg: `{productCode} n'a pas été ajouté. Cet article a une quantité minimum de commande de {quantity}. Veuillez réessayer.`,
  },
}
