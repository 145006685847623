import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from 'shared/Icon'
import styles from './StarIcon.module.scss'

/**
 * Name: StarIcon
 * desc: render Star Icons
 * @param {number} index
 * @param {number} value
 * @param {string} id
 * @param {string} customClass
 */

const StarIcon = ({ index, value, id, customClass, readOnly, dataTest }) => {
  const cursor = readOnly ? 'default' : 'pointer'
  let starIconVariant = ''
  if (Math.ceil(value) === index && value % 1 !== 0) {
    starIconVariant = 'ratingHalf'
  } else if (value >= index) {
    starIconVariant = 'rating'
  } else {
    starIconVariant = 'ratingInactive'
  }

  const starIconClass = classNames(styles.starStyle, {
    [`${styles[customClass]}`]: customClass,
    [styles.cursorNone]: readOnly,
  })
  return (
    <span key={`start_${id}`} className={starIconClass}>
      <Icon variant={starIconVariant} cursor={cursor} data-test={dataTest} />
    </span>
  )
}

// Props Validation
StarIcon.propTypes = {
  index: PropTypes.number,
  value: PropTypes.number,
  id: PropTypes.string,
  readOnly: PropTypes.bool,
  customClass: PropTypes.string,
  dataTest: PropTypes.string,
}

export default StarIcon
