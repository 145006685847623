import PropTypes from 'prop-types'
import Button from 'shared/Button'
import Icon from 'shared/Icon'
import Dialog from 'shared/Dialog'
import { PRODUCT_LEGEND } from 'shared/Dialog/constants'
import Heading from 'shared/Heading'
import messages from './messages'
import styles from './ProductLegendDialog.module.scss'

/**
 * Name: ProductLegend
 * desc: render ProductLegend component
 * @param {bool} open
 * @param {array} utilityIcons
 * @param {func} onHide
 * @param {string} language
 */

const ProductLegendDialog = ({ language, open, utilityIcons, onHide }) => {
  utilityIcons.sort(sortByAlphabet)
  return (
    <Dialog id={PRODUCT_LEGEND} open={open} onHide={onHide} variant="lg">
      <div className={styles.catalogWrapper}>
        <div className={styles.dialogHeader}>
          <Heading as="h4" text={messages[language].topHeadingText} />
          <Button
            variant="link"
            onClick={onHide}
            text={messages[language].closeText}
            data-test="Closebtn"
          />
        </div>
        {utilityIcons.map((utilityIcon, index) => (
          <div key={index} className={styles.dFlex}>
            <div className={`${styles[utilityIcon.icon]} ${styles.iconSize}`}>
              <Icon variant={utilityIcon.icon} title={utilityIcon.linkText} />
            </div>
            <div className={styles.iconDetailsInfo}>
              <div
                className={styles.iconInfo}
                data-test="product-legend-modal-heading"
              >
                {utilityIcon.linkText}
              </div>
              <div className={styles.iconDecription}>
                {utilityIcon.description}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Dialog>
  )
}

// Props Validation
ProductLegendDialog.propTypes = {
  language: PropTypes.string,
  open: PropTypes.bool,
  utilityIcons: PropTypes.array,
  onHide: PropTypes.func,
}

const sortByAlphabet = ({ linkText: linkText1 }, { linkText: linkText2 }) => {
  // Use toUpperCase() to ignore character casing
  linkText1 = linkText1.toUpperCase()
  linkText2 = linkText2.toUpperCase()

  if (linkText1 > linkText2) {
    return 1
  } else if (linkText1 < linkText2) {
    return -1
  }
  return 0
}

export default ProductLegendDialog
