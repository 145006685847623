import { refreshCart } from '@redux/actions'
import { addToCart } from 'api/cartOperations'
import classNames from 'classnames'
import compose from 'lodash/fp/compose'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import { useRef, useContext } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Button from 'shared/Button'
import {
  createErrorMessage,
  showNotification,
} from '~/components/shared/Alerts/AlertNotificationUtils'
import { ENGLISH } from '~/redux/constants'
import { addToCartTelemetrySuccessActions } from '~/services/telemetaryData/cartTelemetary'
import { pressEnter } from '~/services/utils/keyboardUtils'
import { useLoader } from '../CustomHooks/useLoader'
import { dynamicTranslation } from '../DynamicTranslation/DynamicTranslationUtils'
import NumberInput, { useNumberInput } from '../FormControl/NumberInput'
import Stack from '../Stack'
import styles from './AddToCartButton.module.scss'
import {
  CALLING_LOCATION_MAPPING,
  DEFAULT_ADD_TO_CART_QUANTITY,
  INPUT_GROUP,
  PRODUCT_MINIMUM_QUANTITY,
} from './AddToCartButtonConstants'
import {
  addId,
  buildPayload,
  checkMinimumQuantityAndShowError,
} from './AddToCartButtonUtils'
import messages from './messages'
import { AnalyticsContext } from 'shared/Utils/contexts'
import { useOrderContext } from '~/context/OrderContext'

/**
 * Name: AddToCartButton
 * desc: render add to cart button
 * @param {string} type
 * @param {string} name
 * @param {string} language
 * @param {string} productCode
 * @param {string} addToCartText
 * @param {func} refreshCart
 * @param {func} onAddToCartResponse
 * @param {string} messagePosition
 * @param {number} defaultQty
 * @param {string} variant
 * @param {string} btnIcon
 * @param {func} onQuantityChange
 * @param {bool} disabled
 * @param {object} callingLocation
 * @param {string} pageName
 */

const AddToCartButton = ({
  type = null,
  name = null,
  language = ENGLISH,
  productCode,
  text,
  refreshCart,
  onAddToCartResponse,
  defaultQty = DEFAULT_ADD_TO_CART_QUANTITY,
  callingLocation = CALLING_LOCATION_MAPPING,
  richRelevanceUrl,
  variant = INPUT_GROUP,
  icon,
  onQuantityChange,
  disabled = false,
  salesMultiple = 1,
  inputAriaLabel,
  'data-test': dataTest = 'addtocart-btn',
  max = 9999,
  index,
  minimumOrderQuantity = 0,
}) => {
  const ref = useRef()
  const defaultValue =
    minimumOrderQuantity > PRODUCT_MINIMUM_QUANTITY
      ? minimumOrderQuantity
      : defaultQty !== DEFAULT_ADD_TO_CART_QUANTITY
      ? defaultQty
      : salesMultiple
  const {
    handleOnChange,
    handleOnKeyDown: triggerHookKeyDown,
    inputValue: quantity,
    increment,
    triggerNumberInputChange,
    resetToDefaultValue: resetToDefaultQuantity,
    decrement,
  } = useNumberInput({
    step: salesMultiple,
    min: salesMultiple,
    max,
    defaultValue,
    roundToNextStep: true,
    ref,
    minimum: minimumOrderQuantity,
  })

  const hasSalesMultiple = salesMultiple !== 1
  const isValidSalesMultiple = quantity % salesMultiple === 0
  const translations = messages[language]
  const isInputGroup = variant === INPUT_GROUP
  const { isLoading, setPending, setResolved } = useLoader()
  const { segmentLocation = '', index: position = 0 } =
    useContext(AnalyticsContext)
  const { ewayOrderNumber } = useOrderContext()

  async function handleAddCart(value = defaultQty) {
    const numberValue = Number(value)
    if (!numberValue) {
      showItemNotAvailableError()
      return
    }
    if (
      checkMinimumQuantityAndShowError({
        numberValue,
        minimumOrderQuantity,
        productCode,
        message: translations.minimumOrderMsg,
      })
    ) {
      return
    }
    const reqParams = buildPayload(productCode, numberValue)
    setPending()
    const addToCartData = await addToCart(language, { reqParams })
    setResolved()
    const cartData = {
      ...addToCartData,
      CartMessages: addToCartData?.Messages,
    }
    const productPosition = index || position

    addToCartTelemetrySuccessActions(
      cartData,
      callingLocation,
      richRelevanceUrl,
      language,
      productCode,
      segmentLocation,
      productPosition,
      ewayOrderNumber
    )
    onAddToCartResponse?.(cartData)
    refreshCart?.()

    if (isInputGroup) {
      resetToDefaultQuantity()
    }
  }

  function showItemNotAvailableError() {
    const CartMessages = compose(
      addId,
      createErrorMessage
    )(translations.thisItemNotAvailableLabel)

    onAddToCartResponse?.({
      isSuccess: false,
      CartMessages,
    })
  }

  function handleNumberInputOnKeyDown(event) {
    const { value: updatedQuantity } = triggerHookKeyDown(event)
    if (pressEnter(event)) {
      if (!hasSalesMultiple) {
        handleAddCart(updatedQuantity)
      } else {
        if (isValidSalesMultiple) {
          handleAddCart(updatedQuantity)
        } else {
          const replaceMap = {
            '{productCode}': productCode,
            '{salesMultiple}': salesMultiple,
          }
          const errorMessage = dynamicTranslation(
            translations.salesMultiple,
            replaceMap
          )
          showNotification.error(errorMessage, { toast: true })
          resetToDefaultQuantity()
        }
      }
    }
  }

  function handleButtonAddToCart() {
    const { value } = triggerNumberInputChange(quantity)
    handleAddCart(value)
  }

  function handleTextChange(e) {
    const { value } = handleOnChange(e)
    onQuantityChange?.(value, e)
  }

  function handleOnBlur({ target }) {
    const { value } = target
    if (
      checkMinimumQuantityAndShowError({
        numberValue: value,
        minimumOrderQuantity,
        productCode,
        message: translations.minimumOrderMsg,
      })
    ) {
      return
    }
  }
  const textInputName = `btnAddtoCart-${productCode}`
  const buttonClasses = classNames(styles.addToCartButton)
  const buttonProps = {
    name,
    type,
    text: text || translations.addToCartLabel,
    disabled: disabled || isLoading,
    isLoading,
    onClick: handleButtonAddToCart,
  }
  const ariaLabelText = !isEmpty(inputAriaLabel)
    ? inputAriaLabel
    : translations.addToCartLabel

  return (
    <>
      {isInputGroup ? (
        <Stack
          spacing="5px"
          display="flex"
          justify="center"
          className={styles.addToCartButtonWrapper}
        >
          <NumberInput
            ref={ref}
            onKeyDown={handleNumberInputOnKeyDown}
            onChange={handleTextChange}
            onMouseDownArrowDown={decrement}
            onMouseDownArrowUp={increment}
            onBlur={handleOnBlur}
            data-test="addtocart-qty"
            value={quantity}
            name={textInputName}
            step={salesMultiple}
            className={styles.numberInput}
            defaultValue={salesMultiple}
            aria-label={ariaLabelText}
            disabled={disabled || isLoading}
          />
          <Button
            variant="primary"
            className={buttonClasses}
            data-test={dataTest}
            {...buttonProps}
          />
        </Stack>
      ) : (
        <Button
          variant={variant}
          icon={icon}
          data-test={dataTest}
          {...buttonProps}
        />
      )}
    </>
  )
}

AddToCartButton.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  max: PropTypes.number,
  language: PropTypes.string.isRequired,
  productCode: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  refreshCart: PropTypes.func.isRequired,
  salesMultiple: PropTypes.number,
  text: PropTypes.string,
  onAddToCartResponse: PropTypes.func,
  disabled: PropTypes.bool,
  messagePosition: PropTypes.string,
  defaultQty: PropTypes.number,
  variant: PropTypes.string,
  icon: PropTypes.string,
  onQuantityChange: PropTypes.func,
  callingLocation: PropTypes.object,
  pageName: PropTypes.string,
  'data-test': PropTypes.string,
  inputAriaLabel: PropTypes.string,
  richRelevanceUrl: PropTypes.string,
  index: PropTypes.number,
  minimumOrderQuantity: PropTypes.number,
}

function mapStateToProps(state) {
  return {
    cartUpdateTimeStamp: state.state.cartUpdateTimeStamp,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ refreshCart }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartButton)
