import {
  handleAdobeAddToCartCampaignValues,
  removeAdobeAnalyticsEvent,
  updateAndSendTrackingWithAddToCart,
} from 'components/shared/AdobeAnalytics/AdobeAnalyticsUtils'
import isEmpty from 'lodash/isEmpty'
import {
  ADD_TO_CART_EVENTS,
  EVENT4,
  ACTION_TYPE,
} from '~/components/shared/AdobeAnalytics/AdobeAnalyticsConstants'
import { generateProductForAdobeAnalytics } from '~/components/shared/AdobeAnalytics/utils/AdobeAnalyticsCartUtils'
import { MESSAGE_STATUS } from '~/components/shared/Alerts/AlertNotificationUtils/AlertNotificationConstants'
import { sendBloomReachAddCartTracking } from '~/components/shared/BloomReachAnalytics/BloomReachAnalyticsUtils'
import { isEmptyArray, camelCaseKeys } from '~/services/utils'
import { logErrorToConsole } from '../logger'
import { track } from '~/components/shared/SegmentAnalytics/SegmentAnalyticsUtils'
import { getProductVariant } from '~/services/telemetaryData/productDetailsTelemetary/ProductDetailsTelemetary'
import omit from 'lodash/omit'
import {
  SEGMENT_OMITTED_DATA,
  SHOPPING_CART_SEGMENT_LOCATION,
} from '~/components/shared/SegmentAnalytics/SegmentAnalyticsConstants'

const sendLogProviderAddToCartData = () => {
  // silence is golden
  // would be nice to have a logger here
}

export async function handleRichRelevanceData(richRelevanceUrl) {
  if (!richRelevanceUrl) {
    return
  }
  try {
    await fetch(richRelevanceUrl)
  } catch (e) {
    logErrorToConsole(e)
  }
}

export function addToCartTelemetrySuccessActions(
  cartData,
  callingLocation,
  richRelevanceUrl,
  language,
  addedProductCode,
  segmentLocation,
  index,
  ewayOrderNumber
) {
  const { timing } = cartData
  sendAdobeAnalyticsForAddToCart(cartData, callingLocation)
  sendLogProviderAddToCartData(timing)
  handleRichRelevanceData(richRelevanceUrl)
  sendSegmentAnalyticsForAddToCart(
    language,
    cartData,
    addedProductCode,
    segmentLocation,
    index,
    ewayOrderNumber
  )
}

/**
 * Function that sends analytics
 * @param {Object} cartData
 * @param {Object} callingLocationValues
 */
function sendAdobeAnalyticsForAddToCart(cartData, callingLocationValues) {
  const { CartMessages = [] } = cartData
  if (CartMessages.length) {
    let isAddToCartSuccess = false
    CartMessages.map((message) => {
      message.IsCartMessage = true
      if (message.MessageStatus === MESSAGE_STATUS.SUCCESS) {
        isAddToCartSuccess = true
      }
    })
    if (isAddToCartSuccess) {
      removeAdobeAnalyticsEvent(EVENT4)
      sendBloomReachAnalytics(cartData)
      const analyticsProperties = cartData.Analytics?.AnalyticsProperties || {}
      if (isEmpty(analyticsProperties)) {
        return
      }
      const value = handleAdobeAddToCartCampaignValues()
      const campaignOverrideValues = {
        eVar3: value || callingLocationValues.eVar3,
      }
      const callingValues = !isEmpty(callingLocationValues) && {
        ...callingLocationValues,
      }
      const analyticValues = {
        products: generateProductForAdobeAnalytics(analyticsProperties),
        events: ADD_TO_CART_EVENTS,
        ...callingValues,
        ...campaignOverrideValues,
      }
      updateAndSendTrackingWithAddToCart(analyticValues)
    }
  }
}

function sendBloomReachAnalytics(data) {
  const analyticsProperties =
    data?.BloomreachAnalytics?.AnalyticsProperties || {}

  const { products, type } = analyticsProperties
  if (!products) {
    return
  }

  const addedProducts = JSON.parse(products)
  if (!addedProducts || isEmptyArray(addedProducts)) {
    return
  }

  addedProducts.forEach((addedProduct) => {
    sendBloomReachAddCartTracking({
      type,
      ...addedProduct,
    })
  })
}

/**
 * Function that sends segment
 * @param {Object} cartProductList
 * @param {String} cartId
 */
export function sendSegmentProductViewEvent({ cartProductList, cartId }) {
  const products = getProductListProperties({
    products: camelCaseKeys(cartProductList),
    omitValues: [
      ...SEGMENT_OMITTED_DATA.SHOPPING_CART,
      ...SEGMENT_OMITTED_DATA.COMMON,
    ],
  })
  const { cart_id } = getSegmentCommonProps({ cartId })
  track('Cart viewed', {
    cart_id,
    products,
  })
}

/**
 * Function that sends add to cart segment
 * @param {Object} cartData
 * @param {String} addedProductCode
 * @param {String} segmentLocation
 * @param {Number} index
 */

function sendSegmentAnalyticsForAddToCart(
  language,
  cartData,
  addedProductCode,
  segmentLocation,
  index,
  ewayOrderNumber
) {
  const { cartInfo: { products = [] } = {} } = camelCaseKeys(cartData) || {}

  const [product = {}] = products.filter(
    (item) => item.productCode === addedProductCode
  )
  const currentProductProperties = getProperties({
    language,
    data: product,
    cartId: ewayOrderNumber,
    listId: segmentLocation,
    index,
  })
  const cartProductsProperties = getProductListProperties({
    products,
    omitValues: [
      ...SEGMENT_OMITTED_DATA.CART_PRODUCTS,
      ...SEGMENT_OMITTED_DATA.COMMON,
    ],
  })

  track('Product Added', {
    ...currentProductProperties,
    products_in_cart: cartProductsProperties,
  })
}

function getProductListProperties({
  products,
  omitValues = [],
  cartId = '',
  listId = '',
}) {
  return products.map((item, index) => {
    const omittedValues = omit(
      getProperties({ data: item, cartId, listId, index }),
      omitValues
    )
    return omittedValues
  })
}

/**
 * Caution - if needed add or get any new property for segment.
 * Please don't forget to add in omit data(SEGMENT_OMITTED_DATA).
 */
function getProperties({
  language = '',
  data,
  cartId = '',
  listId = '',
  index = 1,
}) {
  const {
    productVariants,
    productCode: product_id,
    brand = '',
    quantity,
    description: name = '',
    unitSellPrice: price = '',
    productCodeJDA: sku = '',
    mediaImages = [],
    imageUrl = '',
    combinedBasePrice: regularPrice = '',
  } = data
  const imagePath = imageUrl || mediaImages[0] || ''
  const variant = getProductVariant(productVariants, product_id)
  const { cart_id } = getSegmentCommonProps({ cartId })
  return {
    brand,
    list_id: listId,
    name,
    price,
    product_id,
    quantity,
    sku,
    variant,
    position: ++index,
    cart_id,
    image_url: imagePath,
    language_iso_code: language,
    regular_price: regularPrice,
    url: window.location.href,
  }
}

function getSegmentCommonProps({ cartId }) {
  return {
    cart_id: cartId,
  }
}

export const updateDeleteCartProductSegmentEvent = ({
  cartProductList = [],
  orderProductId,
  actionType,
  updatedQty,
  language,
  ewayOrderNumber,
  updatedProductList,
}) => {
  const productList = camelCaseKeys(cartProductList)
  const index = productList.findIndex(
    (product) => product.orderProductId === orderProductId
  )
  const productData = productList[index]

  let sendTrackingInfo = false
  if (productData) {
    if (
      actionType === ACTION_TYPE.UPDATE &&
      productData.quantity > Number(updatedQty)
    ) {
      productData.quantity = productData.quantity - Number(updatedQty)
      sendTrackingInfo = true
    } else if (actionType === ACTION_TYPE.DELETE) {
      sendTrackingInfo = true
    }
    if (sendTrackingInfo) {
      const removedProductProperties = getProperties({
        language,
        data: productData,
        cartId: ewayOrderNumber,
        listId: SHOPPING_CART_SEGMENT_LOCATION,
        index,
      })

      const cartProductsProperties = getProductListProperties({
        products: camelCaseKeys(updatedProductList),
        omitValues: SEGMENT_OMITTED_DATA.DELETE_PRODUCT,
        cartId: ewayOrderNumber,
        listId: SHOPPING_CART_SEGMENT_LOCATION,
      })
      track('Product Removed', {
        ...removedProductProperties,
        products_in_cart: cartProductsProperties,
      })
    }
  }
}
