export default {
  en: {
    promotionalItemLabel: 'Promotional Item',
    assemblyRequiredLabel: 'Assembly Required',
    flyerItemLabel: 'Flyer Item',
    shipSepratelyLabel: 'Shipped From a Trusted Staples Partner',
    specialDeliveryItemLabel: 'Special Delivery Item',
    sourcebookItemLabel: 'Sourcebook Item',
    restrictedProductLabel: 'Restricted Product',
    contractPriceLabel: 'Contract Price',
    marketPriceLabel: 'Market Price',
    promotionalItemDescription: 'Indicates that an item is a promo product.',
    assemblyRequiredDescription: 'Assembly required for this item.',
    flyerItemDescription:
      'Indicates that an item is included in a promotional publication.',
    shipSepratelyDescription:
      'Indicates that an item is shipped from a trusted Staples partner.',
    specialDeliveryItemDescription:
      'Indicates that an item requires special delivery.  Additional delivery charges may apply for this item.',
    sourcebookItemDescription:
      'Item appears in the current Staples Professional sourcebook.',
    restrictedProductDescription:
      'You have been restricted from purchasing this item by your organization.',
    contractPriceDescription: 'Indicates that an item is under contract price.',
    marketPriceDescription: `Item's pricing reflects the current market price.`,
  },
  fr: {
    promotionalItemLabel: 'Article promotionnel',
    assemblyRequiredLabel: 'Assemblage requis',
    flyerItemLabel: 'Article en circulaire',
    shipSepratelyLabel: 'Expédié par un partenaire de confiance Staples',
    specialDeliveryItemLabel: 'Article en livraison spéciale',
    sourcebookItemLabel: 'Article en catalogue',
    restrictedProductLabel: 'Produit restreint',
    contractPriceLabel: 'Prix contract',
    marketPriceLabel: 'Prix marché',
    promotionalItemDescription:
      'Indique que le produit est un article promotionnel.',
    assemblyRequiredDescription: `Assemblage requis pour cet article.`,
    flyerItemDescription: `Indique qu'un article est inclus dans une publication promotionnelle.`,
    shipSepratelyDescription: `Indique qu'un article est expédié de la part d'un partenaire de confiance Staples.`,
    specialDeliveryItemDescription: `Indique qu'un article nécessite une livraison spéciale. Des frais de livraison additionnels peuvent s'appliquer pour cet article.`,
    sourcebookItemDescription: `L'article apparaît dans le catalogue actuel de Staples Professionnel.`,
    restrictedProductDescription:
      'Votre organisation ne vous permet pas de commander cet article.',
    contractPriceDescription: `Indique que le prix de l'article provient d'un contrat.`,
    marketPriceDescription: `Le prix de l'article reflète le prix marché courant.`,
  },
}
