import classNames from 'classnames'
import PropTypes from 'prop-types'
import { forwardRef, useRef } from 'react'
import { isProduction } from '~/config/env/environmentUtils'
import Flex from '../../Flex'
import Icon from '../../Icon'
import styles from './NumberInput.module.scss'
import { useNumberInput } from './NumberInputUtils'

const NumberInput = forwardRef((props, refFromProps) => {
  const {
    step: defaultStepValue = 1,
    defaultValue = '',
    onChange: onChangeFromProps,
    onKeyDown: onKeyDownFromProps,
    onMouseDownArrowUp: onMouseDownArrowUpFromProps,
    onMouseDownArrowDown: onMouseDownArrowDownFromProps,
    roundToNextStep,
    min = null,
    max = null,
    value: valueFromProps,
    className,
    error,
    helperText,
    'data-test': dataTest = 'input-number',
    ...rest
  } = props
  {
    const ref = useRef()
    const finalRef = refFromProps || ref
    const {
      inputValue,
      handleOnKeyDown,
      handleOnChange,
      increment,
      decrement,
    } = useNumberInput({
      step: defaultStepValue,
      defaultValue,
      onChange: onChangeFromProps,
      onKeyDown: onKeyDownFromProps,
      min,
      max,
      ref,
      roundToNextStep,
    })
    // Always take values from prop first to allow controlled behavior
    // Only use keydown and change from props when it's controlled by a value
    const isControlled = valueFromProps !== undefined
    const value = isControlled ? valueFromProps : inputValue
    const keyDownEventValue =
      isControlled && !!onKeyDownFromProps
        ? onKeyDownFromProps
        : handleOnKeyDown
    const incrementEventValue =
      isControlled && !!onMouseDownArrowUpFromProps
        ? onMouseDownArrowUpFromProps
        : increment
    const decrementEventValue =
      isControlled && !!onMouseDownArrowDownFromProps
        ? onMouseDownArrowDownFromProps
        : decrement
    const changeEventValue =
      isControlled && !!onChangeFromProps ? onChangeFromProps : handleOnChange

    const containerClasses = classNames(styles.inputContainer, className)
    const inputClasses = classNames(styles.inputNumber, {
      [styles.error]: !!error,
      [styles.inputNumberDisabled]: !!rest.disabled,
    })
    const arrowClasses = classNames(styles.arrow, {
      [styles.arrowDisabled]: !!rest.disabled,
    })

    // Accessibility comments:
    // role="spinbutton" not necessary if type is "number"
    // values: aria-valuemin, aria-valuenow, aria-valuemax
    // not needed either if type is "number"
    // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_aria-valuemin_attribute

    const arrowProps = {
      role: 'button',
      tabIndex: '-1',
    }

    return (
      <div className={containerClasses}>
        <input
          ref={finalRef}
          className={inputClasses}
          type="number"
          onKeyDown={keyDownEventValue}
          onChange={changeEventValue}
          value={value}
          data-test={dataTest}
          {...rest}
        />
        {helperText ? (
          <fieldset aria-hidden="true" className={styles.fieldSet}>
            <legend>
              <span>{helperText}</span>
            </legend>
          </fieldset>
        ) : null}
        <div aria-hidden="true" className={styles.arrowsContainer}>
          <Flex
            className={arrowClasses}
            name="input-arrow-up"
            {...arrowProps}
            data-test="input-arrow-up"
            onMouseDown={incrementEventValue}
          >
            <Icon variant="arrowUpFilled" as="svg" fill="#444444" />
          </Flex>
          <Flex
            name="input-arrow-down"
            className={arrowClasses}
            {...arrowProps}
            data-test="input-arrow-down"
            onMouseDown={decrementEventValue}
          >
            <Icon variant="arrowDownFilled" as="svg" fill="#444444" />
          </Flex>
        </div>
      </div>
    )
  }
})

if (!isProduction()) {
  NumberInput.displayName = 'NumberInput'
}

NumberInput.propTypes = {
  defaultValue: PropTypes.number,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  onMouseDownArrowUp: PropTypes.func,
  onMouseDownArrowDown: PropTypes.func,
  roundToNextStep: PropTypes.bool,
  helperText: PropTypes.node,
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
  'data-test': PropTypes.string,
  error: PropTypes.bool,
}

export default NumberInput
