export default {
  en: {
    topHeadingText: 'Product Feature Legend',
    closeText: 'Close',
  },
  fr: {
    topHeadingText: 'Légende des caractéristique de produits',
    closeText: 'Fermer',
  },
}
