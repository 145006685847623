import PropTypes from 'prop-types'
import InfoLabel from 'shared/InfoLabel'

/**
 * Name: ProductInfoLabel
 * Desc: render product info label
 * @param {string} text
 * @param {string} variantText
 * @param {node} children
 */
const ProductInfoLabel = ({ text, variantText, children }) => {
  return (
    <InfoLabel text={text} variantText={variantText}>
      {children}
    </InfoLabel>
  )
}

// Default Props
ProductInfoLabel.defaultProps = {
  text: '',
}

// Props Validation
ProductInfoLabel.propTypes = {
  text: PropTypes.string.isRequired,
  variantText: PropTypes.string,
  children: PropTypes.node,
}

export default ProductInfoLabel
