export function generateProductForAdobeAnalytics(analyticsProperties = {}) {
  let mergeProducts = ''
  if (analyticsProperties.Products) {
    const productList = analyticsProperties.Products.split(':')
    const revenueList = analyticsProperties.Revenue.split(':')
    const unitList = analyticsProperties.Units.split(':')
    productList.forEach((data, index) => {
      const adobeAnalyticsProducts = {
        category: '',
        product: '',
        quantity: '',
        price: '',
        eventIncrementer: '',
      }
      adobeAnalyticsProducts.product = data
      adobeAnalyticsProducts.eventIncrementer = generateEventsForAdobeAnalytics(
        revenueList[index],
        unitList[index]
      )
      mergeProducts += Object.keys(adobeAnalyticsProducts)
        .map(function (k) {
          return adobeAnalyticsProducts[k]
        })
        .join(';')
      if (productList.length !== index + 1) {
        mergeProducts += ','
      }
    })
  }
  return mergeProducts
}

function generateEventsForAdobeAnalytics(revenue, units) {
  let eventString = ''
  if (revenue) {
    eventString += `event35 = ${revenue}`
  }
  if (eventString !== '') {
    eventString += `|`
  }
  if (units) {
    eventString += `event36 = ${units}`
  }
  return eventString
}
