/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import PropTypes from 'prop-types'
import { handleTooltipShowHide } from 'services/utils'
import EwayLink from 'shared/EwayLink'
import Price from 'shared/Price/Price'
import { ENGLISH } from '@redux/constants'
import {
  GRID_VIEW,
  GRID_VIEW_OFFSET,
  GRID_VIEW_SM,
  LIST_VIEW,
  LIST_VIEW_OFFSET,
  TOOLTIP_UPDATE_TIME,
  ZERO_NUMBER,
} from 'shared/ProductCard/ProductCardConstants'
import Tooltip from 'shared/Tooltip'
import IconInfo from '../../IconInfo'
import messages from './messages'
import styles from './ProductPriceToolTips.module.scss'

const FeeLink = (language, feeObj) => (
  <>
    <EwayLink
      text={`${feeObj.FeeTypeText}:`}
      href={feeObj.link}
      target="_blank"
      textDecoration
      color="primary"
      size="xs"
      data-test="fees-line-tooltip"
    />
    <Price language={language}>{feeObj.FeeAmount}</Price>
  </>
)

/**
 * Name: ProductPriceToolTips
 * Desc: Render price tool tips
 * @param {string} language
 * @param {string} name
 * @param {number} price
 * @param {number} totalPrice
 * @param {number or bool} showSavingPrice
 * @param {number} tariffFee
 * @param {object} tariffFeeObj
 * @param {array} productHandling
 * @param {string} view
 * @param {bool} showSubTotal
 * @param {number} ecoFee
 * @param {object} ecoFeeObj
 * @param {number} saving
 * @param {number} basePrice
 */
const getOffsetFromRight = (price = '', view = '') => {
  if (view === LIST_VIEW) {
    return LIST_VIEW_OFFSET
  }
  return price.toString().length >= 7 ? ZERO_NUMBER : GRID_VIEW_OFFSET
}

const ProductPriceToolTips = ({
  language = ENGLISH,
  name,
  price,
  totalPrice,
  showSavingPrice,
  tariffFee,
  tariffFeeObj,
  productHandling,
  view,
  showSubTotal,
  ecoFee,
  ecoFeeObj,
  saving,
  basePrice,
  calculatedTotalPrice,
}) => {
  return (
    <>
      <span
        className={styles.priceInfo}
        data-tip
        data-for={name}
        tabIndex="0"
        aria-labelledby={name}
        onFocus={handleTooltipShowHide}
        onBlur={handleTooltipShowHide}
      >
        <IconInfo data-test="Icon-fees-tooltips" />
      </span>

      <Tooltip
        id={name}
        place={view === GRID_VIEW ? 'left' : 'top'}
        isSmallView={view === GRID_VIEW || view === GRID_VIEW_SM}
        offset={
          view === GRID_VIEW
            ? { right: getOffsetFromRight(price, view), top: 60 }
            : { left: getOffsetFromRight(price, view) }
        }
        className={styles.priceToolInfo}
        delayHide={TOOLTIP_UPDATE_TIME}
        // delayShow={TOOLTIP_UPDATE_TIME} // TODO: DelayShow is preventing the voice accessibility
        delayUpdate={TOOLTIP_UPDATE_TIME}
      >
        <p>
          <span data-test="fees-line-tooltip">
            {messages[language].priceLabel}
          </span>
          <Price language={language}>{basePrice}</Price>
        </p>

        {showSavingPrice && (
          <p>
            <span className={styles.success}>
              {messages[language].saveLabel}
            </span>
            <Price language={language}>{saving}</Price>
          </p>
        )}
        {ecoFee !== -1 && <p>{FeeLink(language, ecoFeeObj)}</p>}
        {tariffFee !== -1 && <p>{FeeLink(language, tariffFeeObj)}</p>}
        {showSubTotal && (
          <p data-test="fees-line-tooltip" className={styles.borderTop}>
            <Price language={language}>{price}</Price>
          </p>
        )}
        {productHandling &&
          productHandling.length > 0 &&
          productHandling.map((data, index) => (
            <p key={index}>{FeeLink(language, data)}</p>
          ))}

        {(totalPrice || price) && (
          <p className={styles.borderTop}>
            <Price language={language}>{calculatedTotalPrice}</Price>
          </p>
        )}
      </Tooltip>
    </>
  )
}

// set default props
ProductPriceToolTips.defaultProps = {}

// Props Validation
ProductPriceToolTips.propTypes = {
  language: PropTypes.string,
  name: PropTypes.string.isRequired,
  totalPrice: PropTypes.number,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  showSavingPrice: PropTypes.bool,
  tariffFee: PropTypes.number,
  tariffFeeObj: PropTypes.object,
  productHandling: PropTypes.array,
  view: PropTypes.string,
  showSubTotal: PropTypes.bool,
  ecoFee: PropTypes.number,
  ecoFeeObj: PropTypes.object,
  saving: PropTypes.number,
  basePrice: PropTypes.number,
  calculatedTotalPrice: PropTypes.number,
}

export default ProductPriceToolTips
