import isNaN from 'lodash/isNaN'
import range from 'lodash/range'
import isNumber from 'lodash/isNumber'
import { useRef, useState } from 'react'
import { pressDown, pressEnter, pressUp } from '~/services/utils/keyboardUtils'
import { nextTick } from '../../Utils/formUtils'

export function useNumberInput({
  defaultValue,
  step: defaultStepValue = 1,
  min,
  max,
  onKeyDown,
  onChange,
  ref,
  roundToNextStep,
  minimum,
}) {
  const [inputValue, setInputValue] = useState(defaultValue)
  const step = Number(defaultStepValue)
  const currentValue = inputValue || 0
  const updatedThroughKeyPress = useRef(null)
  const roundToNextNumber = roundToNextStep && step !== 1

  // 1st event triggered on keypress
  // Handler that controls only ⬆️ and ⬇️ values
  function handleOnKeyDown(e) {
    const pressUpEvent = pressUp(e)
    const pressEnterEvent = pressEnter(e)
    const pressDownEvent = pressDown(e)
    const directions = {
      isUp: pressUpEvent,
      isDown: pressDownEvent,
    }
    if (!pressUpEvent && !pressDownEvent && !pressEnterEvent) {
      return {}
    }
    // Very important line
    // Cannot be tested through jest
    // prevents double event calls in browser for keyDown for ⬆️ or ⬇️ change events.
    if (pressUpEvent || pressDownEvent) {
      updatedThroughKeyPress.current = true
    }

    // If enter is pressed, don't do calculations
    // return the current value
    if (pressEnterEvent) {
      return handleUpdate(currentValue, e)
    }
    // Up or down was pressed
    if (roundToNextNumber) {
      return handleUpdateNextToRound(currentValue, e, directions)
    }
    const value = directions.isUp ? currentValue + step : currentValue - step
    return handleUpdate(value, e, directions)
  }

  // 2nd event triggered on keypress
  // Handler that updates the input like a normal value
  function handleOnChange(e = {}, valueFromProps) {
    // If the handler was increment via ⬆️ or ⬇️ don't trigger an input change
    if (updatedThroughKeyPress.current) {
      updatedThroughKeyPress.current = false
      return {}
    }
    const { value: fromTarget = '' } = e.target || {}
    const value = fromTarget || valueFromProps
    const finalValue = getNumberValue(value)
    setInputValue(finalValue)
    onChange?.(e, finalValue)
    return { value: finalValue }
  }

  function resetToDefaultValue() {
    setInputValue(defaultValue)
  }

  function increment(e) {
    if (roundToNextNumber) {
      focusOnField(ref)
      return handleUpdateNextToRound(currentValue, e, { isUp: true })
    }
    const value = currentValue + step
    return updateAndFocus(value, e)
  }

  function decrement(e) {
    if (roundToNextNumber) {
      focusOnField(ref)
      return handleUpdateNextToRound(currentValue, e, { isUp: false })
    }
    let value = currentValue
    if (!minimum) {
      value = currentValue - step
    } else if (minimum && currentValue > minimum) {
      value = currentValue - step
    }
    return updateAndFocus(value, e)
  }

  function updateAndFocus(value, e) {
    const result = handleUpdate(value, e)
    focusOnField(ref)
    return result
  }

  function handleUpdateNextToRound(value, e, directions) {
    const direction = directions.isUp ? 'up' : 'down'
    const nextToCloseValue = getNextToCloseRounded(value, step, direction)
    const { isBelowOrAboveMax, isBelowMinValue } = getMinMax(
      min,
      nextToCloseValue,
      max
    )
    if (isBelowOrAboveMax) {
      return handleMixMaxValues(isBelowMinValue, e)
    }
    setInputValue(nextToCloseValue)
    onKeyDown?.(value, e)
    return { value: nextToCloseValue }
  }

  function handleMixMaxValues(isBelowMinValue, e) {
    const minOrMax = isBelowMinValue ? min : max
    setInputValue(minOrMax)
    if (e) {
      onKeyDown?.(minOrMax, e)
    }
    return { value: minOrMax }
  }

  function handleUpdate(value, e) {
    const { isBelowOrAboveMax, isBelowMinValue } = getMinMax(min, value, max)
    if (isBelowOrAboveMax) {
      return handleMixMaxValues(isBelowMinValue, e)
    }
    setInputValue(value)
    if (e) {
      onKeyDown?.(value, e)
    }
    return { value }
  }

  return {
    handleOnKeyDown,
    handleOnChange,
    triggerNumberInputChange: handleUpdate,
    inputValue,
    resetToDefaultValue,
    increment,
    decrement,
  }
}

function getMinMax(min, value, max) {
  const isBelowMinValue = isNumber(min) ? value < min : false
  const isAboveMaxValue = isNumber(max) ? value > max : false
  const isBelowOrAboveMax = isBelowMinValue || isAboveMaxValue
  return { isBelowMinValue, isAboveMaxValue, isBelowOrAboveMax }
}

function focusOnField(ref) {
  // Next tick allows the cursor to be placed on component refresh
  nextTick(() => ref?.current?.focus())
}

export function getNumberValue(value) {
  const asNumber = Number(value)
  const numberedValue = isNaN(asNumber) ? '' : asNumber
  const finalValue = value === '' ? value : numberedValue
  return finalValue
}

function getNextToCloseRounded(current, step, direction) {
  if (!current || !step) {
    return step
  }

  const min = current - step
  const max = current + step + 1
  const greater = (e) => e > current
  const lower = (e) => e < current
  const callback = direction === 'up' ? greater : lower

  const valuesInRange = range(min, max)
    .filter((e) => e % step === 0)
    .filter(callback)
  const [firstValue] = valuesInRange
  return firstValue
}
