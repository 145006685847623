import PropTypes from 'prop-types'
import { ENGLISH } from '~/redux/constants'
import Button from '../Button'
import messages from './messages'
import styles from './BulkBuyButton.module.scss'
import classNames from 'classnames'

function BulkBuyButton({ onClick, language = ENGLISH, ...rest }) {
  const bulkBuyClasses = classNames(styles.bulkBuyButton, rest.className)
  return (
    <Button
      data-test={`bulk-buy`}
      text={messages[language].bulkBuy}
      aria-label={messages[language].bulkBuy}
      role="button"
      variant="primary"
      type="button"
      onClick={onClick}
      {...rest}
      className={bulkBuyClasses}
    />
  )
}

BulkBuyButton.propTypes = {
  language: PropTypes.string,
  onClick: PropTypes.func,
}

export default BulkBuyButton
