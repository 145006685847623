import { format } from 'date-fns'
import { enCA, frCA } from 'date-fns/locale'
import { ENGLISH } from '~/redux/constants'
import { isEnglish } from '~/services/utils/languageUtils'

export const getProductUtilityIcons = (
  message,
  language = ENGLISH,
  product = {}
) => {
  const msg = message[language]
  const {
    isFlyer = false,
    isMarketPrice = false,
    isCatalog = false,
    isRestricted = false,
    isSpecialDelivery = false,
    isContract = false,
    isPromotional = false,
    isAssembly = 0,
    isShipSeparately = false,
    isContractIconSetting = false,
  } = product

  // Total of 9 possible icons
  const utilityIcons = [
    {
      iconName: 'Flyer',
      isShow: isFlyer,
      linkText: msg.flyerItemLabel,
      description: msg.flyerItemDescription,
      link: '#',
      icon: 'flyer',
      iconColor: 'flyer',
      isFlyer,
      listIcon: true,
    },
    {
      iconName: 'MarketPrice',
      isShow: isMarketPrice,
      linkText: msg.marketPriceLabel,
      description: msg.marketPriceDescription,
      link: '#',
      icon: 'marketPrice',
      iconColor: 'marketPrice',
      isMarketPrice,
      listIcon: true,
    },
    {
      iconName: 'Catalog',
      isShow: isCatalog,
      linkText: msg.sourcebookItemLabel,
      description: msg.sourcebookItemDescription,
      link: '#',
      icon: 'sourcebook',
      iconColor: 'sourcebook',
      isCatalog,
      listIcon: true,
    },
    {
      iconName: 'Restricted',
      isShow: isRestricted,
      linkText: msg.restrictedProductLabel,
      description: msg.restrictedProductDescription,
      link: '#',
      icon: 'restricted',
      iconColor: 'restricted',
      isRestricted,
      listIcon: true,
    },
    {
      iconName: 'Special Delivery',
      isShow: isSpecialDelivery,
      linkText: msg.specialDeliveryItemLabel,
      description: msg.specialDeliveryItemDescription,
      link: '#',
      icon: 'specialDelivery',
      iconColor: 'specialDelivery',
      isSpecialDelivery,
      listIcon: true,
    },
    {
      iconName: 'Contract',
      isShow: isContract,
      linkText: msg.contractPriceLabel,
      description: msg.contractPriceDescription,
      link: '#',
      icon: 'contract',
      iconColor: 'contract',
      isContract,
      listIcon: isContractIconSetting,
    },
    {
      iconName: 'Ship Seprately',
      isShow: isShipSeparately,
      linkText: msg.shipSepratelyLabel,
      description: msg.shipSepratelyDescription,
      link: '#',
      icon: 'shipSeprately',
      iconColor: 'shipSeprately',
      isPromotional,
      listIcon: true,
    },
    {
      iconName: 'Promotiona litem',
      isShow: isPromotional,
      linkText: msg.promotionalItemLabel,
      description: msg.promotionalItemDescription,
      link: '#',
      icon: 'promo',
      iconColor: 'promo',
      isAssembly,
      listIcon: true,
    },
    {
      iconName: 'Assembly required',
      isShow: isAssembly,
      linkText: msg.assemblyRequiredLabel,
      description: msg.assemblyRequiredDescription,
      link: '#',
      icon: 'assembly',
      iconColor: 'assembly',
      isShipSeparately,
      listIcon: true,
    },
  ]
  return utilityIcons.filter(({ listIcon }) => listIcon)
}

export const getFlyerText = (message, language, onSaleEndDate) => {
  const dateFormat = isEnglish(language) ? 'MMMM dd, yyyy' : 'dd MMMM yyyy'
  const locale = isEnglish(language) ? { locale: enCA } : { locale: frCA }
  const saleDate = onSaleEndDate
    ? format(new Date(onSaleEndDate), dateFormat, locale)
    : null
  const flyerDateText = saleDate
    ? isEnglish(language)
      ? ` - Only until ${saleDate}`
      : ` - Seulement jusqu'au ${saleDate}`
    : ''

  return message + flyerDateText
}
