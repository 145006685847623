export default {
  en: {
    priceLabel: 'Price',
    saveLabel: 'Save',
    tariffFeeLabel: 'Tariff Fee:',
  },
  fr: {
    priceLabel: 'Prix',
    saveLabel: 'Épargnez',
    tariffFeeLabel: 'Frais de douane:',
  },
}
