import classNames from 'classnames'
import PropTypes from 'prop-types'
import Button from 'shared/Button'
import { ENGLISH } from '~/redux/constants'
import { useToggler } from '../../CustomHooks/useToggler'
import messages from './messages'
import ProductLegendDialog from './ProductLegend'
import styles from './ProductUtilityIcons.module.scss'
import {
  getFlyerText,
  getProductUtilityIcons,
} from './ProductUtilityIconsUtils'

/**
 * Name: ProductUtilityIcons
 * desc: render product utility icons
 * @param {string} language
 * @param {string} iconStyle
 * @param {string} view
 * @param {string} className
 */

const ProductUtilityIcons = ({
  iconStyle,
  className,
  language = ENGLISH,
  view,
  isHovered,
  ...rest
}) => {
  const utilityIcons = getProductUtilityIcons(messages, language, rest)
  const { isOpen, close, open } = useToggler()
  const classes = classNames(
    className,
    styles.productUtilityIcon,
    { [`${styles[view]}`]: view },
    { [`${styles[iconStyle]}`]: iconStyle }
  )

  return (
    <div
      className={classes}
      data-product-footer={`${isHovered ? '' : 'hidden'}`}
    >
      {utilityIcons
        .filter(({ isShow }) => isShow)
        .map((utilityIcon) => {
          const title = utilityIcon.isFlyer
            ? getFlyerText(utilityIcon.linkText, language, rest.onSaleEndDate)
            : utilityIcon.linkText

          return (
            <div
              className={`${styles.btnIcon} ${styles[utilityIcon.icon]}`}
              key={utilityIcon.icon}
            >
              <Button
                icon={utilityIcon.icon}
                title={title}
                onClick={open}
                className={styles.popupIcon}
                data-test="product-utility-button-icon"
              />
            </div>
          )
        })}

      <ProductLegendDialog
        language={language}
        open={isOpen}
        onHide={close}
        utilityIcons={utilityIcons}
      />
    </div>
  )
}

// Props Validation
ProductUtilityIcons.propTypes = {
  language: PropTypes.string,
  iconStyle: PropTypes.string,
  className: PropTypes.string,
  view: PropTypes.string,
  isHovered: PropTypes.bool,
  rest: PropTypes.shape({
    isContractIconSetting: PropTypes.bool,
    showContractIcon: PropTypes.bool,
    isFlyer: PropTypes.bool,
    isAssembly: PropTypes.int,
    isSpecialDelivery: PropTypes.bool,
    isPromotional: PropTypes.bool,
    isMarketPrice: PropTypes.bool,
    isCatalog: PropTypes.bool,
    isRestricted: PropTypes.bool,
  }),
}

export default ProductUtilityIcons
