import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import styles from './Rating.module.scss'
import StarIconsList from './StarIconsList'

/**
 * Name: Rating
 * Desc: render rating
 * @param {*} props
 */
const Rating = (props) => {
  const {
    editing,
    className,
    language,
    review,
    starCount,
    value: initValue,
    id,
    readOnly,
    color,
  } = props
  const colorClass = styles[color]

  const [value, setValue] = useState(initValue)

  useEffect(() => {
    setValue(initValue)
  }, [initValue])

  const editingClass = !editing ? styles.cursorDefault : styles.cursorPointer
  const ratingsClasses = classNames(
    styles.ratingContainer,
    editingClass,
    colorClass,
    styles[className]
  )

  const stars = [...Array(starCount).keys()]

  return (
    <div className={ratingsClasses} data-rating="selected">
      <div className={styles.ratingStyle}>
        {stars.map((star, index) => (
          <StarIconsList
            id={id}
            onStarClick={handleOnStarClick}
            onInputRatingChange={onInputRatingChange}
            data={props}
            value={value}
            setValue={setValue}
            index={index}
            readOnly={readOnly}
            key={`startlist-${index}`}
          />
        ))}
      </div>
      {(!!review || review === 0) && (
        <span>
          ({review} {`${language === 'en' ? 'reviews' : 'Commentaires'}`})
        </span>
      )}
    </div>
  )
}

/**
 * onChange
 * Desc: call when rating change
 * @param {*} startValue
 * @param {*} props
 */
const onInputRatingChange = (startValue, props) => {
  const { editing, value } = props
  if (!editing) {
    return
  }
  if (value !== null) {
    return
  }
}

/**
 * Name: handleOnStarClick
 * @param {*} index
 * @param {*} value
 * @param {*} name
 * @param {*} e
 * @param {*} props
 * @param {*} setValue
 */
const handleOnStarClick = (index, value, name, e, props, setValue) => {
  e.stopPropagation()
  const { handleOnStarClick, editing } = props
  if (!editing) {
    return
  }
  setValue(index)
  handleOnStarClick?.(index, value, name, e)
}

// Props Validation
Rating.propTypes = {
  language: PropTypes.string.isRequired,
  review: PropTypes.number,
  value: PropTypes.number,
  editing: PropTypes.bool,
  readOnly: PropTypes.bool,
  starCount: PropTypes.number,
  className: PropTypes.string,
  id: PropTypes.string,
  color: PropTypes.string,
}

/**
 *  Default props settings
 */
Rating.defaultProps = {
  starCount: 5,
  editing: true,
  emptyStarColor: '#f3f3f3',
}
export default Rating
