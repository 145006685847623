import { dynamicTranslation } from '~/components/shared/DynamicTranslation/DynamicTranslationUtils'
import { showNotification } from '~/components/shared/Alerts/AlertNotificationUtils'
import { DEFAULT_ADD_TO_CART_QUANTITY } from './AddToCartButtonConstants'

/**
 * Ads an "id" to the array
 * If an array like so is passed
 *
 * [{}]
 *
 * Methods returns
 *
 * [{id: 1}]
 * And so on.
 *
 * @param {*} array
 */
export const addId = (array) =>
  array.map((item, index) => ({ ...item, id: index + 1 }))

/**
 *
 * @param {Array|string} productCode - Accepts and array of string product codes
 * @param {number} quantity - accepts a quantity
 * @returns
 */
export function buildPayload(
  productCode,
  quantity = DEFAULT_ADD_TO_CART_QUANTITY
) {
  return Array.isArray(productCode)
    ? productCode
    : [
        {
          ProductCode: productCode,
          Quantity: quantity,
        },
      ]
}

const showMinimumOrderQuantityError = ({
  productCode,
  minimumOrderQuantity,
  message,
}) => {
  const replaceMap = {
    '{productCode}': productCode,
    '{quantity}': minimumOrderQuantity,
  }
  const errorMessage = dynamicTranslation(message, replaceMap)
  showNotification.error(errorMessage, { toast: true })
}

export const checkMinimumQuantityAndShowError = ({
  numberValue,
  minimumOrderQuantity,
  message,
  productCode,
}) => {
  let multipleQuantityProducts = []
  if (Array.isArray(productCode)) {
    productCode.forEach(({ Quantity, minimumQuantity, ProductCode }) => {
      const quantity = Number(Quantity)
      if (quantity < minimumQuantity) {
        showMinimumOrderQuantityError({
          minimumOrderQuantity: minimumQuantity,
          message,
          productCode: ProductCode,
        })
        multipleQuantityProducts = [...multipleQuantityProducts, ProductCode]
      }
    })
    return !!multipleQuantityProducts?.length
  } else if (
    !Array.isArray(productCode) &&
    numberValue < minimumOrderQuantity
  ) {
    showMinimumOrderQuantityError({
      minimumOrderQuantity,
      message,
      productCode,
    })
    return true
  }
  return false
}
